<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t(`cip.plat.wfOps.ops.title.indexHeadTitle`)"
                 @head-tk="handleCompleteTask(null, true)"
                 @head-bh="handleCompleteTask(null, false)"
                 @head-bgshr="handleUserSelect({type: 'assignee', checkType: 'radio'})"
                 @head-zb="handleUserSelect({type: 'transfer', checkType: 'radio'})"
                 @head-wt="handleUserSelect({type: 'delegate', checkType: 'radio'})"
                 @head-cs="handleUserSelect({type: 'copy', checkType: 'checkbox'})"
                 @head-cb="handleUrgeTask(null)"
                 @head-zz="handleTerminateProcess(null)"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @page-refresh-change="onLoad"
                 @row-flow="handleFlow"></grid-layout>

    <!--  <basic-container>
    <avue-crud ref="crud"
               :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               v-model="form"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="onLoad(page, query)"
               @on-load="onLoad">
      <template #menuLeft>
        <el-button v-if="permission.wf_ops_pass"
                   :disabled="loading"
                   size="mini"
                   type="success"
                   icon="el-icon-hiddenTrouble"
                   @click="handleCompleteTask(null, true)">通过</el-button>
        <el-button v-if="permission.wf_ops_reject"
                   :disabled="loading"
                   size="mini"
                   type="danger"
                   icon="el-icon-close"
                   @click="handleCompleteTask(null, false)">驳回</el-button>
        <el-button v-if="permission.wf_ops_change_assignee"
                   :disabled="loading"
                   size="mini"
                   type="warning"
                   icon="el-icon-user"
                   @click="handleUserSelect({type: 'assignee', checkType: 'radio'})">变更审核人</el-button>
        <el-button v-if="permission.wf_ops_transfer"
                   :disabled="loading"
                   size="mini"
                   type="primary"
                   icon="el-icon-user"
                   @click="handleUserSelect({type: 'transfer', checkType: 'radio'})">转办</el-button>
        <el-button v-if="permission.wf_ops_delegate"
                   :disabled="loading"
                   size="mini"
                   type="success"
                   icon="el-icon-user"
                   @click="handleUserSelect({type: 'delegate', checkType: 'radio'})">委托</el-button>
        <el-button v-if="permission.wf_ops_copy"
                   :disabled="loading"
                   size="mini"
                   type="info"
                   icon="el-icon-s-promotion"
                   @click="handleUserSelect({type: 'copy', checkType: 'checkbox'})">抄送</el-button>
        <el-button v-if="permission.wf_ops_urge"
                   :disabled="loading"
                   size="mini"
                   type="warning"
                   icon="el-icon-warning"
                   @click="handleUrgeTask(null)">催办</el-button>
        <el-button v-if="permission.wf_ops_terminate"
                   :disabled="loading"
                   size="mini"
                   type="danger"
                   icon="el-icon-s-opportunity"
                   @click="handleTerminateProcess(null)">终止</el-button>
      </template>
      <template #processDefinitionName="{row}">
        <el-link v-if="permission.wf_ops_detail"
                 style="font-size: 12px;"
                 type="primary"
                 @click="handleDetail(row)">{{row.processDefinitionName}}</el-link>
        <span v-else>{{row.processDefinitionName}}</span>
      </template>
      <template #isSuspended="{row}">
        <el-tag v-if="row.isSuspended"
                size="mini"
                type="danger">挂起</el-tag>
        <el-tag v-else
                size="mini">激活</el-tag>
      </template>
      <template #menu="{row}">
        <el-button v-if="permission.wf_ops_follow"
                   type="text"
                   size="small"
                   icon="el-icon-search"
                   @click="handleFlow(row)">流程图</el-button>
        <el-dropdown style="margin-left: 5px;">
          <el-button size="mini"
                     type="text">更多操作<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="permission.wf_ops_pass"
                                @click.native="handleCompleteTask(row.taskId, true)">通过</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_reject"
                                @click.native="handleCompleteTask(row.taskId, false)">驳回</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_transfer"
                                @click.native="handleUserSelect({type: 'transfer', checkType: 'radio'}, row)">转办</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_delegate"
                                @click.native="handleUserSelect({type: 'delegate', checkType: 'radio'}, row)">委托</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_dispatch"
                                @click.native="getProcessNodes(row.taskId, row.processInstanceId, 'dispatch')">调度</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_rollback"
                                @click.native="getProcessNodes(row.taskId, row.processInstanceId, 'rollback')">指定回退</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_terminate"
                                @click.native="handleTerminateProcess(row.taskId)">终止</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_add_multi_instance && row.isMultiInstance"
                                @click.native="handleUserSelect({type: 'addMultiInstance', checkType: 'checkbox'}, row)">加签</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_delete_multi_instance && row.isMultiInstance"
                                @click.native="handleDeleteMultiInstance(row)">减签</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_copy"
                                @click.native="handleUserSelect({type: 'copy', checkType: 'checkbox'}, row)">抄送</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_urge"
                                @click.native="handleUrgeTask(row.taskId)">催办</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_active && row.isSuspended"
                                @click.native="handleChangeStatus(row, false)">激活</el-dropdown-item>
              <el-dropdown-item v-if="permission.wf_ops_suspend && !row.isSuspended"
                                @click.native="handleChangeStatus(row, true)">挂起</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </avue-crud>

    <el-dialog :visible.sync="bpmnVisible"
               append-to-body
               destroy-on-close
               title="流程图">
      <wf-design ref="bpmn"
                 style="height: 500px;"
                 :options="bpmnOption"></wf-design>
    </el-dialog>
    &lt;!&ndash; 人员选择弹窗 &ndash;&gt;
    <user-select ref="user-select"
                 :hiddenTrouble-type="checkType"
                 @onConfirm="handleUserSelectConfirm"></user-select>
    &lt;!&ndash; 选择节点弹窗 &ndash;&gt;
    <el-dialog :visible.sync="nodeVisible"
               append-to-body
               title="选择节点">
      <avue-form v-if="nodeVisible"
                 v-model="nodeForm"
                 :option="nodeOption"
                 @submit="handleNodeSubmit"></avue-form>
    </el-dialog>
    <el-drawer :visible.sync="detailVisible"
               :title="form.processDefinitionName"
               custom-class="wf-drawer"
               size="60%"
               append-to-body>
      <task-detail v-if="detailVisible"
                   :taskId="form.taskId"
                   :processInstanceId="form.processInstanceId"></task-detail>
    </el-drawer>
  </basic-container>-->
    <CommonDialog v-if="bpmnVisible" customClass="wf-dialog" width="50%" :showBtn="false" :dialogTitle="$t(`cip.plat.wfOps.ops.title.flowChartHeadTitle`)" @cancel="bpmnVisible = false">
      <wf-design ref="bpmn"
                 style="height: 500px;"
                 :options="bpmnOption"></wf-design>
    </CommonDialog>
    <!--    人员选择弹窗 -->
    <user-select ref="user-select"
                 :check-type="checkType"
                 @onConfirm="handleUserSelectConfirm"></user-select>
    <!--    &lt;!&ndash; 选择节点弹窗 &ndash;&gt;-->
    <el-dialog :visible.sync="nodeVisible"
               append-to-body
               :title="$t(`cip.plat.wfOps.ops.title.selectHeadTitle`)">
      <avue-form v-if="nodeVisible"
                 v-model="nodeForm"
                 :option="nodeOption"
                 @submit="handleNodeSubmit"></avue-form>
    </el-dialog>
    <el-drawer :visible.sync="detailVisible"
               :title="form.processDefinitionName"
               custom-class="wf-drawer"
               size="60%"
               append-to-body>
      <task-detail v-if="detailVisible"
                   :taskId="form.taskId"
                   :processInstanceId="form.processInstanceId"></task-detail>
    </el-drawer>
  </div>

</template>

<script>
import {detail} from '@/api/plugin/workflow/process'
import {
  getList,
  completeTask,
  changeTaskStatus,
  changeTaskAssignee,
  transferTask,
  delegateTask,
  copyTask,
  urgeTask,
  terminateProcess,
  processNodes,
  rollbackTask,
  dispatchTask,
  addMultiInstance,
  deleteMultiInstance
} from "@/api/plugin/workflow/ops";
import {userList} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";

import UserSelect from '../process/components/user-select.vue'
import TaskDetail from './detail.vue'
import exForm from '../mixins/ex-form'
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonDialog from "@/components/CommonDialog"
export default {
  mixins: [exForm],
  components: {
    UserSelect, TaskDetail, GridLayout, HeadLayout, CommonDialog
  },
  data() {
    return {
      checkType: 'radio',
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        size: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        selection: true,
        dialogType: 'drawer',
        addBtn: false,
        editBtn: false,
        delBtn: false,
        align: 'center',
        searchMenuSpan: 6,
        searchSize: 'mini',
        searchIndex: 3,
        searchIcon: true,
        column: [
          {
            label: this.$t('cip.plat.wfOps.ops.field.nameOfTheProcess'),
            prop: 'processDefinitionName',
            search: true,
            overHidden: true,
            width: 200,
            align:'left',
          },
          {
            label: this.$t('cip.plat.wfOps.ops.field.ProcessIdentification'),
            prop: 'processDefinitionKey',
            search: true,
            overHidden: true,
            width: 200,
            align:'left',
          },
          {
            label: this.$t('cip.plat.wfOps.ops.field.SerialNumber'),
            prop: 'serialNumber',
            search: true,
            overHidden: true,
            align:'left',
          },
          {
            label: this.$t('cip.plat.wfOps.ops.field.category'),
            row: true,
            type: 'tree',
            width: 200,
            align:'center',
            overHidden: true,
            dicUrl: '/api/sinoma-workflow/design/category/tree',
            props: {
              label: 'name',
              value: 'id'
            },
            prop: "category",
            search: true,
          },
          {
            label: this.$t('cip.plat.wfOps.ops.field.TheCurrentNode'),
            prop: 'taskName',
            width: 180,
            align:'center',
            overHidden: true,
            search: true
          },
          {
            label: this.$t('cip.plat.wfOps.ops.field.Approver'),
            prop: 'assignee',
            search: true,
            width: 180,
            align:'center',
            overHidden: true
          },
          {
            label: this.$t('cip.plat.wfOps.ops.field.applicant'),
            prop: 'startUsername',
            width: 100,
            align:'center',
            overHidden: true,
            search: true
          },
          {
            label: this.$t('cip.plat.wfOps.ops.field.ApplicationTime'),
            prop: 'createTime',
            align: 'center',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm',
            overHidden: true,
            width: 165,
          },
          {
            label: this.$t('cip.plat.wfOps.ops.field.Status'),
            prop: 'isSuspended',
            align: 'center',
            overHidden: true,
            type: 'select',
            width: 100,
            // dicData: [{
            //   label: this.$t('cip.plat.wfOps.ops.field.activation'),
            //   value: true
            // }, {
            //   label: this.$t('cip.plat.wfOps.ops.field.Suspend'),
            //   value: false
            // }],
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=activation_Suspend",
            search: true
          },
          {
            label: this.$t('cip.plat.wfOps.ops.field.TimeRange'),
            prop: 'startTimeRange',
            type: 'datetime',
            overHidden: true,
            width: 165,
            dataType: 'string',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            hide: true,
            search: true,
            searchRange: true,
          }
        ]
      },
      data: [],
      bpmnVisible: false,
      bpmnOption: {},
      nodeVisible: false,
      nodeForm: {},
      nodeOption: {
        column: [{
          label: this.$t('cip.plat.wfOps.ops.field.node'),
          prop: 'nodeId',
          type: 'select',
          props: {
            label: 'nodeName',
            value: 'nodeId'
          },
          span: 24,
          rules: [{
            required: true,
            message: this.$t(`cip.cmn.rule.selectWarning`) + this.$t(`cip.plat.wfOps.ops.field.node`),
            trigger: 'change'
          }]
        }]
      },
      nodeType: '',
      detailVisible: false
    };
  },
  computed: {
    ...mapGetters(["permission", "language"]),
    ids() {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        ids.push(ele.taskId);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: this.$t('cip.plat.wfOps.ops.field.nameOfTheProcess'),
          prop: "name",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`) + this.$t('cip.plat.wfOps.ops.field.nameOfTheProcess'),
        },
      ]
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.wf_ops_pass) {
        result.push({
          label: this.$t('cip.plat.wfOps.ops.btn.crossBtn'),
          emit: "head-tk",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_ops_reject) {
        result.push({
          label: this.$t('cip.plat.wfOps.ops.btn.rejectBtn'),
          emit: "head-bh",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      if (this.permission.wf_ops_change_assignee) {
        result.push({
          label: this.$t('cip.plat.wfOps.ops.btn.bgrBtn'),
          emit: "head-bgshr",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      if (this.permission.wf_ops_transfer) {
        result.push({
          label: this.$t('cip.plat.wfOps.ops.btn.transferBtn'),
          emit: "head-zb",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      if (this.permission.wf_ops_transfer) {
        result.push({
          label: this.$t('cip.plat.wfOps.ops.btn.entrustBtn'),
          emit: "head-wt",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      if (this.permission.wf_ops_copy) {
        result.push({
          label: this.$t('cip.plat.wfOps.ops.btn.copyBtn'),
          emit: "head-cs",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      if (this.permission.wf_ops_urge) {
        result.push({
          label: this.$t('cip.plat.wfOps.ops.btn.pressBtn'),
          emit: "head-cb",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      if (this.permission.wf_ops_terminate) {
        result.push({
          label: this.$t('cip.plat.wfOps.ops.btn.stopBtn'),
          emit: "head-zz",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let result = [];
      if (this.permission.wf_ops_follow) {
        result.push({
          label: this.$t('cip.plat.wfOps.ops.btn.flowBtn'), //流程图
          emit: "row-flow",
          type: "primary",
          icon: "el-icon-plus",
        })
      }
      //新增按钮
      return result;
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty(searchForm) {
      this.onLoad(this.page, searchForm);
    },
    handleDeleteMultiInstance(row) {
      const {taskId} = row
      this.$confirm(this.$t(`cip.plat.wfOps.ops.msg.signOff`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: 'warning',
      }).then(() => {
        this.loading = true
        deleteMultiInstance({taskId}).then(() => {
          this.$message.success(this.$t(`cip.plat.wfOps.ops.msg.signOffSuccess`))
          this.onLoad(this.page, this.query)
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
      })
    },
    handleDetail(row) {
      this.dynamicRoute(row, 'detail', true).then(() => {
        this.form = {...row}
        this.detailVisible = true
      })
    },
    rollbackTask,
    dispatchTask,
    handleNodeSubmit(form, done) {
      const {nodeId, taskId} = form
      const param = {nodeId, taskId}
      this.loading = true
      this[`${this.nodeType}Task`](param).then(() => {
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        this.form = {}
        done()
        this.nodeVisible = false
        this.onLoad(this.page, this.query)
      }).catch(() => {
        done()
        this.loading = false
      })
    },
    getProcessNodes(taskId, processInstanceId, type) {
      this.nodeType = type
      const param = {processInstanceId}
      if (type == 'rollback') param.taskId = taskId
      processNodes(param).then(res => {
        this.findObject(this.nodeOption.column, 'nodeId').dicData = res.data.data
        this.nodeVisible = true
        this.nodeForm = {
          ...this.nodeForm,
          taskId, processInstanceId
        }
      })
    },
    handleCompleteTask(taskId, pass) {
      if (!taskId) {
        if (this.$refs.gridLayOut.selectionList.length === 0) {
          this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`))
          return
        }
        taskId = this.ids
      }
      this.$confirm(pass ? this.$t(`cip.plat.wfOps.ops.msg.cross`) : this.$t(`cip.plat.wfOps.ops.msg.reject`), {
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.loading = true
        completeTask({taskId, pass}).then(() => {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
          this.onLoad(this.page, this.query)
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
      })
    },
    handleUrgeTask(taskId) {
      if (!taskId) {
        if (this.$refs.gridLayOut.selectionList.length === 0) {
          this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`))
          return
        }
        taskId = this.ids
      }
      this.$confirm(this.$t(`cip.plat.wfOps.ops.msg.press`), {
        type: 'warning',
      }).then(() => {
        this.loading = true
        urgeTask({taskId}).then(() => {
          this.$message.success(this.$t(`cip.plat.wfOps.ops.msg.pressSuccess`))
          this.onLoad(this.page, this.query)
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
      })
    },
    handleTerminateProcess(taskId) {
      if (!taskId) {
        if (this.$refs.gridLayOut.selectionList.length === 0) {
          this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`))
          return
        }
        taskId = this.ids
      }
      this.$confirm(this.$t(`cip.plat.wfOps.ops.msg.stop`), {
        type: 'warning',
      }).then(() => {
        this.loading = true
        terminateProcess({taskId}).then(() => {
          this.$message.success(this.$t(`cip.plat.wfOps.ops.msg.stopSuccess`))
          this.onLoad(this.page, this.query)
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
      })
    },
    handleChangeStatus(row, isSuspended) {
      this.loading = true
      changeTaskStatus({taskId: row.taskId, isSuspended}).then(() => {
        this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        this.onLoad(this.page, this.query)
      }).catch(() => {
        this.loading = false
      })
    },
    // 人员选择弹窗
    handleUserSelect({type, checkType}, row) {
      if (row) this.$refs.gridLayOut.selectionList = [row]
      if (this.$refs.gridLayOut.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`))
        return
      }
      this.$refs['user-select'].visible = true
      this.userSelectType = type
      this.checkType = checkType
    },
    handleUserSelectConfirm(id, name) {
      switch (this.userSelectType) {
        case 'assignee':
          this.$confirm(this.$t(`cip.plat.wfOps.ops.msg.bgr`) + `<span style='color: red;'> ${name} </span>`, {
            type: 'warning',
            dangerouslyUseHTMLString: true,
          }).then(() => {
            this.$refs['user-select'].visible = false
            this.loading = true
            changeTaskAssignee({taskId: this.ids, assignee: id}).then(() => {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              this.onLoad(this.page, this.query)
              this.$refs.gridLayOut.selectionList = []
            }).catch(() => {
              this.loading = false
            })
          }).catch(() => {
          })
          break;
        case 'transfer':
          this.$confirm(this.$t(`cip.plat.wfOps.ops.msg.transfer`) + `<span style='color: red;'> ${name} </span>`, {
            type: 'warning',
            dangerouslyUseHTMLString: true,
          }).then(() => {
            this.$refs['user-select'].visible = false
            this.loading = true
            transferTask({taskId: this.ids, assignee: id}).then(() => {
              this.$message.success(this.$t(`cip.plat.wfOps.ops.msg.transferSuccess`))
              this.onLoad(this.page, this.query)
              this.$refs.gridLayOut.selectionList = []
            }).catch(() => {
              this.loading = false
            })
          }).catch(() => {
          })
          break;
        case 'delegate':
          this.$confirm(this.$t(`cip.plat.wfOps.ops.msg.delegate`) + `<span style='color: red;'> ${name} </span>`, {
            type: 'warning',
            dangerouslyUseHTMLString: true,
          }).then(() => {
            this.$refs['user-select'].visible = false
            this.loading = true
            delegateTask({taskId: this.ids, assignee: id}).then(() => {
              this.$message.success(this.$t(`cip.plat.wfOps.ops.msg.delegateSuccess`))
              this.onLoad(this.page, this.query)
              this.$refs.gridLayOut.selectionList = []
            }).catch(() => {
              this.loading = false
            })
          }).catch(() => {
          })
          break;
        case 'copy':
          this.$confirm(this.$t(`cip.plat.wfOps.ops.msg.copy`) + `<span style='color: red;'> ${name} </span>`, {
            type: 'warning',
            dangerouslyUseHTMLString: true,
          }).then(() => {
            this.$refs['user-select'].visible = false
            this.loading = true
            copyTask({taskId: this.ids, assignee: id}).then(() => {
              this.$message.success(this.$t(`cip.plat.wfOps.ops.msg.copySuccess`))
              this.onLoad(this.page, this.query)
              this.$refs.gridLayOut.selectionList = []
              this.$refs.crud.toggleSelection()
            }).catch(() => {
              this.loading = false
            })
          }).catch(() => {
          })
          break;
        case 'addMultiInstance':
          this.$confirm(this.$t(`cip.plat.wfOps.ops.msg.addMultiInstance`), {
            type: 'warning',
          }).then(() => {
            this.$refs['user-select'].visible = false
            addMultiInstance({taskId: this.ids, assignee: id}).then(() => {
              this.$message.success(this.$t(`cip.plat.wfOps.ops.msg.addMultiInstanceSuccess`))
              this.onLoad(this.page, this.query)
              this.$refs.gridLayOut.selectionList = []
            }).catch(() => {
              this.loading = false
            })
          }).catch(() => {
          })
          break;
      }
    },
    handleFlow(row) {
      const {taskId, processInstanceId} = row
      detail({taskId, processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data

        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }

        this.bpmnVisible = true
      })
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.$refs.gridLayOut.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    async onLoad(page, params = {}) {
      this.loading = true;
      this.page = page;
      const param = Object.assign(params, this.query)
      const {assignee} = param
      if (assignee) {
        const res = await userList(1, -1, {name: assignee})
        if (res.data.data.records && res.data.data.records.length > 0) {
          param.assignee = res.data.data.records.map(d => d.id).join(',')
        } else {
          this.page.total = 0
          this.data = []
          this.loading = false
        }
      }
      getList(page.currentPage, page.pageSize, param).then(res => {
        const data = res.data.data
        this.$refs.gridLayOut.page.total = data.total
        this.data = data.records
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.wf-drawer {
  .el-drawer__body {
    padding: 0 20px;
    overflow: auto;
  }
}

::v-deep .bjs-powered-by {
  display: none;
}
</style>
